<template lang="pug">
    section#mosaico
        ul.mosaico
            li(v-for="item, index in getImage", :class="`_0${index+1}`")
                img(:src="item.image")
        .content
            Editor(:contents="editor")
            Link(:contents='contents.content').link {{contents.content.name}}
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'
import Link from '@components/Link/Link'

export default {
    name: "section-mosaico",
    props,
    components: {
        Editor,
        Link,
    },
    computed: {
        getImage() {
            return this.contents.content.mosaico.map(item => ({
                ...item,
                image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item}`
            }))
        },
        editor() {
            return {content: this.contents.content.editor}
        },
    },
}
</script>

<style lang="stylus" scoped src="./MosaicoHomeSection.styl"></style>